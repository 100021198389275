(function buttonlinkScript($, Drupal) {
  Drupal.behaviors.faq_item = {
    attach(context) {
      var faqTrigger = document.getElementsByClassName("faq__trigger");
      var i;

      $('.faq__trigger').unbind().click(function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $(this).next('.faq__response').slideToggle(200);
        $(this).parents('.faq-item').toggleClass('active');
      })
    },
  };
}(jQuery, Drupal));
